import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/LitreryClub/LanguageAssociation/a1.jpg';
// import p2 from 'assests/Photos/Clubs/LitreryClub/LanguageAssociation/a2.jpg';
// import p3 from 'assests/Photos/Clubs/LitreryClub/LanguageAssociation/a3.jpg';
// import p4 from 'assests/Photos/Clubs/LitreryClub/LanguageAssociation/a4.jpg';
// import p5 from 'assests/Photos/Clubs/LitreryClub/LanguageAssociation/a5.jpg';


import SideBar1 from 'views/LiteraryClub/SideBar1';




import { Typography } from '@mui/material';
import Container from 'components/Container';



const LanguageAssociation = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/LanguageAssociation/a1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/LanguageAssociation/a2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/LanguageAssociation/a3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/LanguageAssociation/a4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/LanguageAssociation/a5.webp`;



  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      

  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      LANGUAGE ASSOCIATION FINALS 2022-23
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
       Class: 6 to 8 Date: 25 January 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Language Association of National public school, Yeshwanthpur hosted the finals of the Language Association Activity for Classes 6 to 8 on Wednesday, 25 January, 2023. The event was an inter-house Group Song Competition that aimed at showcasing student’s talent through music, while also enhancing their language and presentation skills.
       <br/>
       The participants presented a variety of songs in different languages like Kannada, Sanskrit and Hindi. They showcased their mastery over voice modulation, pitch, rhythm and tone.
       Judges applauded the efforts of the students and the teachers and emphasised the importance of team spirit in everyone’s life.
       <br/><br/>
</Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
      ‘Music binds our soul, hearts and emotions. It cheers the spirit and lightens the hearts’.
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
     
<br/>
        </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SideBar1 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default LanguageAssociation;